import React from 'react'
import cn from 'classnames'
import { darkGrey, lightGrey } from 'lib/theme'

import NavigationBar from 'components/NavigationBar/NavigationBar'
import WaveWrapper from 'components/WaveWrapper/WaveWrapper'

import styles from './PageHeader.module.css'

type PageHeaderProps = {
  logoVariant?: string | undefined
  hideSearchButton?: boolean
  greyBackground?: boolean
  headerContent: React.ReactNode
  headerBackground?: string
  headerImage?: string
  headerVideo?: React.ReactNode
  hideBottom: boolean
  nakedHeader?: boolean
  headerBorder?: boolean
  waveColor?: string
  navigationBarDarkContent?: boolean
  bottom?: boolean
  headerRef: React.MutableRefObject<HTMLDivElement>
}

const PageHeader = ({
  logoVariant,
  hideSearchButton,
  greyBackground,
  headerContent,
  headerBackground = darkGrey,
  headerImage,
  headerVideo,
  headerBorder,
  nakedHeader,
  waveColor,
  hideBottom,
  bottom,
  navigationBarDarkContent,
  headerRef,
}: PageHeaderProps): JSX.Element => {
  return (
    <>
      <header
        ref={headerRef}
        className={cn(styles.header, {
          [styles.headerVideo]: headerVideo,
          [styles.nakedHeader]: nakedHeader,
        })}
        style={{
          backgroundColor: nakedHeader ? 'transparent' : headerBackground,
          backgroundImage: headerImage && `url(${headerImage})`,
        }}
      >
        {headerVideo && <div className={styles.video}>{headerVideo}</div>}
        {nakedHeader ? (
          <NavigationBar
            logoVariant={logoVariant}
            hideSearchButton={hideSearchButton}
            greyBackground={greyBackground}
            headerBorder={headerBorder}
            darkContent={navigationBarDarkContent}
          />
        ) : (
          <WaveWrapper
            waveColor={waveColor || lightGrey}
            bottom={bottom}
            hideBottom={hideBottom}
          >
            <NavigationBar
              logoVariant={logoVariant}
              hideSearchButton={hideSearchButton}
              greyBackground={greyBackground}
              headerBorder={headerBorder}
              darkContent={navigationBarDarkContent}
            />
            {headerContent}
          </WaveWrapper>
        )}
      </header>
    </>
  )
}

export default PageHeader
